import ArticleLayout from "../components/articleLayout/ArticleLayout";
import Divider from "../components/divider/Divider";
import PageTitle from "../components/contentHeadings/PageTitle";
import React from 'react';
import SeeAvailableButton from "../components/button/SeeAvailableButton";
import Seo from "../components/Seo";
import SubHeading from "../components/contentHeadings/SubHeading";

const RensAvVippeextensions = () => {

    return(
        <ArticleLayout>
            <Seo title={'Rens av vippeextensions'} />
            <PageTitle>Rens av vippeextensions</PageTitle>
            <p>Når man har vippeextensions er det viktig å holde dem rene! De vil fange opp støv, sminkerester, hudrester og lignende som ikke forsvinner av seg selv. </p>
            <p>Hvis man ikke renser vippene risikerer man at vippeextensions kan falle av. Vippeextensions lim kan bli løst opp av olje fra huden eller sminkerester. Hvis du har volumvipper vil de tynne hårene i viftene klistre seg i hverandre på grunn av fett, olje og sminkerester, og de vil ikke se luftige og jevne ut lenger. </p>
            <SubHeading>Hvordan vaske vippeextensions?</SubHeading>
            <p>Litt som ditt eget hodehår så er løsningen å vaske dem med såpe og børste dem! Det skader ikke å la vann renne over vippene (de tåler vann). Vann alene løser ikke opp fett og olje, så man trenger også en olje- og silikonfri såpe. Er du usikker på om såpen din er oljefri kan du kjøpe en vippeshampoo i salongen. </p>
            <p>Det beste er å vaske vippene daglig. Da vil de definitivt holde best. Hvis du synes at det er vanskelig å få inn i rutinen din, og hvis du ikke bruker sminke på vippeextensions-ene så foreslår vi at du gjør det når du vasker håret i dusjen! Da kan du enkelt skylle dem med vann når du er ferdig.</p>
            <ul>
                <li>Såpen kan man ta rett på vippene, fingrene eller på rense-penselen. </li>
                <li>Gjerne bruk en myk børste eller pensel for å jobbe skummet inn i vippene. Ikke bruk en mascara-børste, fordi den kommer ikke helt ned til huden. Vi anbefaler ikke Q-tips, fordi bomull kan lett henge seg fast nederst på vippene. </li>
                <li>Det viktigste stedet å vaske er helt nederst ved huden, mellom vippene. Det er her alt samler seg opp, og det er her limet sitter. </li>
                <li>For å se om det er noe som kan ha samlet seg i vippene kan du dra opp øyelokket, slik at du kan se mellom hårene. </li>
                <li>Vippeextensions-lim er kraftig, så ikke vær redd for å vaske dem grundig. Hvis du ser at noen faller av så er det nok dine naturlige vipper som bytter seg ut. Dra penselen nedover, oppover og til siden.</li>
                <li>Vask alt vekk med vann, og børst gjennom med en børste du får i salongen til slutt!</li>
            </ul>
            <Divider color={'white'} />
            <p>Merker du at huden din, rundt øynene, har blitt tørr eller rød? Da kan det hende at de ikke har blitt renset godt nok og det kan være best å fjerne vippene og ta en liten pause. </p>
            <p>Synes du vippeextensions faller fort av? Det kan skyldes andre ting også, for eksempel vippesyklusen din, soving på siden eller at limet ikke fikk tørke slik det skal.</p>
            <SubHeading>Vippeshampoo</SubHeading>
            <p>I salongen kan du kjøpe vippeshampoo (200,-). Det er et skum vi bruker på våre kunder som fjerner sminken og som renser godt. Den forsvinner helt med vann, og etterlater seg ingen rester på vippene. Den er olje- og silikonfri og mild mot huden. </p>
            <SeeAvailableButton>Se ledige timer</SeeAvailableButton>
        </ArticleLayout>
    )
};

export default RensAvVippeextensions;