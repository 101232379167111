
import * as grid from "../../styles/grid.module.scss";
import * as styles from "./article-layout.module.scss";
import ArticleNavigation from "./ArticleNavigation";
import ContentMargins from "../layout/ContentMargins";
import Layout from "../layout/layout";
import React from 'react';

const ArticleLayout = ({ children }) => {

    return(
        <Layout>
            <ContentMargins className={styles.topMargin}>
                <div className={grid.container}>
                    <div className={grid.nav}>
                        <ArticleNavigation/>
                    </div>
                    <div className={grid.content}>
                        { children }
                    </div>
                </div>
                {/*<Grid container spacing={1}>*/}
                {/*    <Grid item xs={12} md={3}>*/}
                {/*        <ArticleNavigation/>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={12} md={9}>*/}
                {/*        { children }*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </ContentMargins>
        </Layout>
    )
};

export default ArticleLayout;