
import * as styles from './article-navigation.module.scss';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, {useCallback, useMemo, useState} from 'react';
import { Link } from 'gatsby';
import {vlPages, vxPages} from "../../data/linkData";

const inlineStyles = {
    minHeight: "90px",
    display: "flex",
    alignItems: "center"
};

const ArticleNavigation = () => {
    const [mobileOpen, setMobileOpen] = useState(false);

    const details = useMemo(() => (
        <React.Fragment>
            <span className={styles.listHeading}>Vippeextensions</span>
            <ul className={styles.list}>
                {
                    vxPages.map((page, index) => (
                        <li key={'vx_' + index.toString()}>
                            <Link to={page.path}>{ page.label }</Link>
                        </li>
                    ))
                }
            </ul>
            <span className={styles.listHeading}>Vippeløft</span>
            <ul className={styles.list}>
                {
                    vlPages.map((page, index) => (
                        <li key={'vl_' + index.toString()}>
                            <Link to={page.path}>{ page.label }</Link>
                        </li>
                    ))
                }
            </ul>
        </React.Fragment>
    ), []);

    const toggleMobileOpen = useCallback(() => {
        setMobileOpen(prev => !prev);
    }, []);

    const toggleMobileOpenEnter = useCallback(event => {
        if (event.key === "Enter"){
            setMobileOpen(prev => !prev);
        }
    }, []);

    return(
        <nav>
            <div className={styles.altHiddenMdUp}>
                <div style={inlineStyles}>
                    <div className={styles.mobileWrapper}>
                        <div
                            className={styles.buttonContent}
                            onClick={toggleMobileOpen}
                            onKeyDown={toggleMobileOpenEnter}
                            tabIndex={0}
                            role={"button"}
                        >
                            Oversikt over artikler
                            <ExpandMore className={styles.expandMore + (mobileOpen ? ' ' + styles.expandMoreRotate : '')}/>
                        </div>
                        { mobileOpen ? <div className={styles.mobileContent}>{ details }</div> : null }
                    </div>
                </div>
            </div>
            <div className={styles.altHiddenSmDown}>
                { details }
            </div>
        </nav>
    )
};

export default ArticleNavigation;